import { Card } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import * as api from '../../api';
import { ExportCSV, RegisterCloudAPIButton, SearchBar } from '../../components';
import { CloudApiMigration } from '../../types';
import CloudApiMigrationTable from './RegisterTable';

type Props = {
  isAdmin: boolean | null;
};

function CloudApiMigrationStatus({ isAdmin}: Props) {
  const [migrations, setMigrations] = useState<CloudApiMigration[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const prevSearchText = useRef(searchText);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      if (searchText !== prevSearchText.current) {
        setLoading(true);
      }
      const { migrations, total } = await api.cloudMigration.findCloudRegister(page, size, searchText);
      setMigrations(migrations);
      setTotal(total);
    } catch (error) {
      setMigrations([]);
      setTotal(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchText, page, size]);

  const handleSearch = (searchValue: string) => {
    setSearchText(searchValue);
    setPage(1);
  };

  const onPaginationChanged = (page: number, pageSize?: number | undefined) => {
    setPage(page);
    pageSize && setSize(pageSize);
  };

  const getExportData = async () => {
    const dataTable = await api.cloudMigration.getExportData();
    return dataTable || [];
  };

  return (
    <Card className="accounts text-center" title="Whatsapp Business Account">
      {/* <Card.Text>All my accounts here</Card.Text> */}
      {migrations && (
        <>
          <div className="flex flex-jc-sb flex-ai-c features-box">
            <SearchBar
              placeholder="You are looking for ( Phone name/ Phone number) ..."
              isLoading={isLoading}
              onHandleSearch={handleSearch}
            />

            <div className="flex flex-jc-sb flex-ai-c" style={{ marginLeft: '20px' }}>
              {/* <div style={{ flex: 1 }} className="export">
                <ExportCSV getExportData={getExportData} fileName={'accounts'} disable={false} combined />
              </div> */}
              {isAdmin && (
                <div style={{ flex: 1 }} className="add-waba">
                  <RegisterCloudAPIButton />
                </div>
              )}
            </div>
          </div>
          <CloudApiMigrationTable
            migrations={migrations}
            loading={isLoading}
            isAdmin={isAdmin}
            page={page}
            size={size}
            total={total}
            onPaginationChanged={onPaginationChanged}
          />
        </>
      )}
    </Card>
  );
}

export default CloudApiMigrationStatus;
